import React, { useState } from "react"

import { SiteContext, ContextProviderComponent } from "../context/mainContext"
import { DENOMINATION } from "../../providers/inventoryProvider"
import { FaTimes, FaLongArrowAltRight } from "react-icons/fa"
import { Link } from "gatsby"
import CartLink from "../components/CartLink"
import { slugify } from "../../utils/helpers"
import Image from "../components/Image"
import { loadStripe } from "@stripe/stripe-js"

const Cart = ({ context }) => {
  const { numberOfItemsInCart, cart, removeFromCart, total } = context
  const cartEmpty = numberOfItemsInCart === Number(0)
  const stripePromise = loadStripe(process.env.GATSBY_APP_STRIPE_PK)
  const [errorMessage, setErrorMessage] = useState(null)
  // const [orderCompleted, setOrderCompleted] = useState(false)

  const handleClick = async event => {
    event.preventDefault()
    const stripe = await stripePromise
    setErrorMessage("checking out...")
    const skus = Object.entries(
      cart
        .map(item => item.sku)
        .reduce((allSkus, sku) => {
          if (sku in allSkus) {
            allSkus[sku]++
          } else {
            allSkus[sku] = 1
          }
          return allSkus
        }, {})
    ).map(item => ({
      sku: item[0],
      quantity: item[1],
    }))
    const { clearCart } = context
    const error = await stripe.redirectToCheckout({
      items: skus,
      successUrl: `https://shop.kielbyrne.com/cart?session_id=${null}`,
      cancelUrl: "https://shop.kielbyrne.com/cancel",
      shippingAddressCollection: {
        allowedCountries: ["US", "CA"],
      },
    })
    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setErrorMessage("Stripe has not yet loaded...")
      return
    }

    if (error) {
      setErrorMessage(error.message)
      return
    }

    // TODO call API
    // try {
    //   createOrder(order)
    // } catch (error) {
    //   console.log(error)
    //   setErrorMessage(error)
    // }
    console.log("finished...")
    clearCart()
    // setOrderCompleted(true)
  }

  return (
    <>
      <CartLink />
      <div className="flex flex-col items-center pb-10">
        <div
          className="
          flex flex-col w-full
          c_large:w-c_large
        "
        >
          <div className="pt-10 pb-8">
            <h1 className="text-5xl font-light">Your Cart</h1>
          </div>

          {cartEmpty ? (
            <h3>No items in cart.</h3>
          ) : (
            <div className="flex flex-col">
              <div className="">
                {cart.map(item => {
                  return (
                    <div className="border-b py-10" key={item.id}>
                      <div className="flex items-center">
                        <Link to={slugify(item.name)}>
                          {item.images.map(image => (
                            <Image
                              className="w-32 m-0"
                              src={image}
                              alt={item.name}
                            />
                          ))}
                        </Link>
                        <Link to={slugify(item.name)}>
                          <p className="m-0 pl-10 text-gray-600 text-sm">
                            {item.name}
                          </p>
                        </Link>
                        <div className="flex flex-1 justify-end">
                          <p className="m-0 pl-10 text-gray-900 tracking-tighter font-semibold">
                            {DENOMINATION + item.price}
                          </p>
                        </div>
                        <button
                          onClick={() => removeFromCart(item)}
                          className="m-0 ml-10 text-gray-900 text-s cursor-pointer"
                        >
                          <FaTimes />
                        </button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          <div className="flex flex-1 justify-end py-8">
            <p className="text-sm pr-10">Total</p>
            <p className="font-semibold tracking-tighter">
              {DENOMINATION + total}
            </p>
          </div>
          {!cartEmpty && (
            <div className="flex flex-1 flex-col md:flex-row">
              <div className="md:pt-20">
                {errorMessage ? <div>{errorMessage}</div> : ""}
                <div className="pl-2 flex flex-1 justify-end pt-2 md:pt-8 pr-4">
                  <p className="text-sm pr-10">Subtotal</p>
                  <p className="tracking-tighter w-38 flex justify-end">
                    {DENOMINATION + total}
                  </p>
                </div>
                <div className="pl-2 flex flex-1 justify-end pr-4">
                  <p className="text-sm pr-10">Shipping</p>
                  <p className="tracking-tighter w-38 flex justify-end">
                    Calculated at Checkout
                  </p>
                </div>
                <div className="pl-2 flex flex-1 justify-end bg-gray-200 pr-4 pt-6">
                  <p className="text-sm pr-10">Total</p>
                  <p className="font-semibold tracking-tighter w-38 flex justify-end">
                    {DENOMINATION + total}
                  </p>
                </div>
                <button
                  type="submit"
                  disabled={errorMessage}
                  onClick={handleClick}
                  className="flex-none bg-secondary hover:bg-black text-white font-bold py-2 px-20 mt-4 rounded focus:outline-none focus:shadow-outline"
                >
                  <div className="cursor-pointer flex">
                    <p className="text-white-100 text-sm mr-2 mb-0">
                      Proceed to check out
                    </p>
                    <FaLongArrowAltRight className="text-white mt-1" />
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

function CartWithContext(props) {
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {context => <Cart {...props} context={context} />}
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}

export default CartWithContext
